/* Sokabau tenant styles */

@import "../colors";
@import "../settings";

.kmta__tenant--sokabau {
    .sokabau__logo {
        padding: 1rem;
        background-color: var(--header-bottom-bg-color);
        height: 270%;
        transform: translateY(-30%);
        box-shadow: 1px 1px 4px 2px rgba(195, 195, 195, 0.5);
    }

    .kmta__faqHome {
        background: linear-gradient(
            to bottom,
            rgba(236, 237, 237, 1) 0%,
            rgba(198, 199, 200, 1) 100%
        );
    }

    .button.large.important.expanded.kmta_productView__addToCartButton {
        background: var(--f-6-secondary-button-bg-color);
        border-color: var(--f-6-secondary-button-bg-color);
    }

    .dropdown.menu li.active > a,
    .menu.is-dropdown-submenu
        li.is-submenu-item.is-dropdown-submenu-item.active
        > a {
        font-weight: $global-weight-bold;
    }

    .kmta__contactperson.is-expanded {
        height: 200px;
        width: 350px;
    }

    /* Checkout Form */
    #kmta_coupon_sokabau {
        font-weight: normal;
    }
    @include breakpoint(small down) {
        .kmta__headerTop {
            .kmta__tenantLogoAlt {
                display: none;
            }
            .kmta__headerUserinfo {
                margin-top: 0;
                margin-bottom: 1rem;
            }
        }
        .kmta__headerBottomRow {
            justify-content: space-between;
            a.sokabau__logo {
                transform: translateY(-15%);
                width: 50%;
                svg {
                    width: 100%;
                    height: auto;
                }
            }
            .kmta__menu {
                justify-content: end;
                text-align: right;
            }
        }
    }
    #lp_survey_51166 {
        display: none;
        box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.7);
    }
    #lp_survey_51166 .b-close {
        position: absolute;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0 0 0.7rem 0 rgba(0, 0, 0, 0.5);
        color: #222;
        cursor: pointer;
        display: inline-block;
        text-align: center;
        text-decoration: none;
        width: 1.5rem;
        line-height: 1.5rem;
        height: 1.5rem;
        top: -0.5rem;
        right: -0.5rem;
    }
    
    .kmta__heroTitle {
        padding-top: 3rem;
        margin-bottom: 0.3rem;
        font-size: 3rem;
        text-align: center;
        color: var(--white);
    }

    .kmta__subTitle {
        margin-bottom: 1.8rem;
        font-size: 1.22rem;
        text-align: center;
        color: #dbdada;
    }
}
